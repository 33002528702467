import BigPageLink from "components/BigPageLink";
import Carousel from "components/Carousel";
import Footer from "components/Footer";
import AudioBlock from "components/blocks/AudioBlock";
import FullWidthImageBlock from "components/blocks/FullWidthImageBlock";
import ImageGridBlock from "components/blocks/ImageGridBlock";
import TextBlock from "components/blocks/TextBlock";
import VideoBlock from "components/blocks/VideoBlock";
import SimpleItemRenderer from "components/carousel-item-renderers/SimpleItemRenderer";
import Hero from "components/case/Hero";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React from "react";
import { Box } from "rebass";
import { withTheme } from "styled-components";
import BaseTemplate from "templates/base";

class CaseTemplate extends BaseTemplate {
  get usePageReadyTimeout() {
    return false;
  }

  componentDidMount() {
    this.pageReadyTimeout = setTimeout(this.onPageReady, 15000);
  }

  componentWillUnmount() {
    clearTimeout(this.pageReadyTimeout);
  }

  onHeroImageLoaded = () => {
    this.setPageReadyTimeout(1500);
  };

  renderBlock(block, prevBlock, nextBlock) {
    const apiKey = block.model ? block.model.apiKey : null;
    const groupWithNextBlock = block.groupWithNextBlock || false;
    const { data, theme } = this.props;
    const { ready } = this.state;
    const { page } = data;
    let Cmp = null;
    let extras = {};
    switch (apiKey) {
      case "case_study_hero":
        extras.client = page.client;
        extras.onImageLoaded = this.onHeroImageLoaded;
        Cmp = Hero;
        break;
      case "video_block":
        Cmp = VideoBlock;
        break;
      case "text_block":
        Cmp = TextBlock;
        break;
      case "case_study_carousel":
        Cmp = Carousel;
        extras = { ItemRenderer: SimpleItemRenderer };
        break;
      case "image_grid_block":
        Cmp = ImageGridBlock;
        break;
      case "full_width_image_block":
        Cmp = FullWidthImageBlock;
        break;
      case "audio_block":
        Cmp = AudioBlock;
        break;
      default:
        break;
    }
    extras.groupWithNextBlock = groupWithNextBlock;
    if (Cmp) {
      return (
        <Cmp
          key={block.id}
          theme={theme}
          {...block}
          {...extras}
          ready={ready}
        />
      );
    }
    return null;
  }

  render() {
    const { data, theme } = this.props;
    const { page } = data;
    const { seoMetaTags, body } = page;
    let heroImage = null;
    try {
      heroImage = body.find(
        (bodyPart) => bodyPart.model.apiKey === `case_study_hero`
      ).image.fluid;
    } catch (e) {}

    return (
      <React.Fragment>
        <HelmetDatoCms seo={seoMetaTags}>
          <link rel="preload" as="image" href="/loader-blue.gif" />
          {heroImage && (
            <link
              rel="preload"
              as="image"
              href={heroImage.src}
              imagesizes={heroImage.sizes}
              imagesrcset={heroImage.srcSet}
            />
          )}
        </HelmetDatoCms>
        <Box position="relative" display="block" pt={[150, 150, 80]}>
          {body &&
            body.map((block, idx) => {
              const prevBlock = body[idx - 1] || null;
              const nextBlock = body[idx + 1] || null;
              return this.renderBlock(block, prevBlock, nextBlock);
            })}
        </Box>
        <BigPageLink
          linkLabel={`All the work`}
          link={`/work#cases`}
          theme={theme}
          big={true}
        />
        <Footer theme={theme} globals={this.props.pageContext.globals} />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query ($uid: String) {
    page: datoCmsCaseStudy(id: { eq: $uid }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      client {
        name
      }
      body {
        ... on DatoCmsCaseStudyHero {
          model {
            apiKey
          }
          id
          heading
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            url
            alt
            fluid(
              maxWidth: 1440
              imgixParams: { auto: "compress,format", fm: "jpg", q: 75 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsVideoBlock {
          model {
            apiKey
          }
          id
          anchor
          aspect
          loop
          video {
            url
          }
          videoUrl {
            url
            title
            provider
            thumbnailUrl
            providerUid
          }
          overlayImage {
            url
            alt
            fluid(
              maxWidth: 1366
              imgixParams: { auto: "compress,format", fm: "jpg", q: 75 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          captionNode {
            childMarkdownRemark {
              html
            }
          }
          groupWithNextBlock
        }
        ... on DatoCmsCaseStudyCarousel {
          id
          model {
            apiKey
          }
          items {
            caption
            image {
              url
              alt
              fluid(
                maxWidth: 1366
                imgixParams: { auto: "compress,format", fm: "jpg", q: 75 }
              ) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
          }
          groupWithNextBlock
        }
        ... on DatoCmsTextBlock {
          id
          model {
            apiKey
          }
          showAside
          asidePrefix
          asideHeading
          contentNode {
            childMarkdownRemark {
              html
            }
          }
          groupWithNextBlock
        }
        ... on DatoCmsImageGridBlock {
          model {
            apiKey
          }
          id
          backgroundColor {
            hex
          }
          constrainToGrid
          constrainImageSize
          mobileSize
          tabletSize
          desktopSize
          largeScreenSize
          captionNode {
            childMarkdownRemark {
              html
            }
          }
          images {
            url
            width
            height
            alt
            fluid(
              maxWidth: 1366
              imgixParams: { auto: "compress,format", q: 75 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          groupWithNextBlock
        }
        ... on DatoCmsFullWidthImageBlock {
          model {
            apiKey
          }
          id
          image {
            url
            alt
            fluid(
              maxWidth: 1920
              imgixParams: { auto: "compress,format", q: 75 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          captionNode {
            childMarkdownRemark {
              html
            }
          }
          link
          fullBleed
          groupWithNextBlock
        }
        ... on DatoCmsAudioBlock {
          model {
            apiKey
          }
          id
          title
          file {
            url
          }
          groupWithNextBlock
        }
      }
    }
  }
`;

export default withTheme(CaseTemplate);
